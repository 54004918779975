import React from "react";
import {styled} from "@mui/system";
import {Button} from "@mui/material";
import {colors} from "../../../styles/globalStyles";
import {Label} from "./rateGrid";
import renderStars from "../../../util/renderStars";

const ButtonStyle = styled(Button)({
    borderRadius: 23,
    display: "flex",
    gap:4,
    flexDirection: "column",

    "&.light":{
        background: colors.transparentMain,
        color: colors.main
    },
    "&.transparent":{
        background: colors.transparentWhite,
        color: colors.white,
    },

    "&:active": {
        background: "linear-gradient(0deg, rgba(246, 182, 59, 0.1), rgba(246, 182, 59, 0.1)), #FFFFFF",
        border: "4px solid rgba(246, 182, 59, 0.36)",
        color: colors.yellow
    },
    "&:hover": {
        background: 'rgba(0, 0, 0, 0.1)'
    },

    "&.selected": {
        background: "linear-gradient(0deg, rgba(246, 182, 59, 0.1), rgba(246, 182, 59, 0.1)), #FFFFFF",
        border: "4px solid rgba(246, 182, 59, 0.36)",
        color: colors.yellow
    }
})

const getCaption = (value, maxScore) =>{
    if (maxScore === 4){
        switch (value){
            case 0:
                return "никогда"
            case 1:
                return "иногда"
            case 2:
                return "50/50"
            case 3:
                return "часто"
            case 4:
                return "всегда"

        }
    }

    if (maxScore === 5){
        switch (value){
            case 1:
                return "никогда"
            case 2:
                return "иногда"
            case 3:
                return "50/50"
            case 4:
                return "часто"
            case 5:
                return "всегда"
        }
    }

    if (maxScore === 9){
        if (value <=1)
            return "никогда"
        if (value <=3)
            return "иногда"
        if (value <=5)
            return "50/50"
        if (value <=7)
            return "часто"
        if (value <=9)
            return "всегда"
    }

    if (maxScore === 10){
        if (value <=2)
            return "никогда"
        if (value <=4)
            return "иногда"
        if (value <=6)
            return "50/50"
        if (value <=8)
            return "часто"
        if (value <=10)
            return "всегда"
    }
}

const RateButton = ({ value, className, onClick, variant, maxScore}) =>{

    const resultVariant = variant === "light" ? "light" : "transparent"
    const buttonClassName =  `${className} ${resultVariant}`


    return (
        <ButtonStyle className={buttonClassName} onClick={onClick}>
            <Label variant={"h1"}>
                {value}
            </Label>
            <div>
                {renderStars(value)}
            </div>
            <div>
                { buttonClassName.includes("selected") && getCaption(value, maxScore)}
            </div>
        </ButtonStyle>
    )
}

export default RateButton