import React, { useEffect, useRef, useState } from 'react';
import { Chip, styled, Typography } from '@mui/material';
import { colors } from '../styles/globalStyles';
import SliderCard from './pages/trackerPage/sliderCard';
import Selector from './pages/trackerPage/selector';
import useElementOnScreen from './pages/trackerPage/useElementOnScreen';
import RateModal from './modals/rateModal';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userInfoAtomArr, kssDataAtom, kssDataSelector, userKssDataForAdminPanelAtom, userForAdminPanelAtom } from '../recoil/atoms/userAtom';
import { weekProgressDataAtom } from '../recoil/atoms/mySessionPageAtoms';
import { getMyWeekProgress } from '../api/stats';
import FeedbackModal from './feedbackModal';
import { getColorType } from '../pages/trackerPage';
import { AdminUserAtom } from '../recoil/atoms/adminPanelAtoms';

const Layout = styled('div')({
    background: colors.transparentMain,
    borderRadius: 40,
    overflow: 'hidden',
});

const Header = styled('header')({
    padding: 27,

    display: 'flex',
    alignItems: 'center',
    gap: 5,
});

const Avatar = styled('img')({
    width: 24,
    height: 24,
    borderRadius: 1000,
});

const TypographyHeader = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '100%',
});

const KeepStopStart = styled('div')({
    paddingInline: 27,
    marginBottom: 17,

    display: 'flex',
    justifyContent: 'space-between',
    gap: 6,
});

const Cards = styled('main')({
    minHeight: 320,
    display: 'flex',
    alignItems: 'stretch',
    gap: 6,
    overflowX: 'auto',
    scrollSnapType: 'x mandatory',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
});

const MySessionMyKss = () => {
    const { avatar } = useRecoilValue(userInfoAtomArr);

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    };

    const [infoOpen, setInfoOpen] = useState(false);
    const [selectedType, setSelectedType] = useState(null);

    const [keepRef, isKeepVisible] = useElementOnScreen(options);
    const [stopRef, isStopVisible] = useElementOnScreen(options);
    const [startRef, isStartVisible] = useElementOnScreen(options);

    const data = useRecoilValue(kssDataAtom);
    const userKssDataForAdminPanel = useRecoilValue(userKssDataForAdminPanelAtom);
    const setData = useSetRecoilState(kssDataSelector)
    const setWeekData = useSetRecoilState(weekProgressDataAtom)
    const [openModal, setOpenModal] = useState(false)
    const adminData = useRecoilValue(AdminUserAtom);
    const userForAdminPanel = useRecoilValue(userForAdminPanelAtom);

    const sliderRef = useRef();

    const kssData = adminData.appAdminPanel ? userKssDataForAdminPanel : data;
    
    useEffect(() => {
        if (selectedType){
            const targetIndex = kssData.findIndex(i=>i?.id === selectedType.id)
            const newData = Object.assign([], kssData)
            newData[targetIndex] = selectedType.score?.value === -1 ? {...selectedType, score: null} : selectedType 
            setData(newData)
            getMyWeekProgress(userForAdminPanel.id || null)
                .then((res)=> setWeekData(res.data))
                .catch((err) => {
                    console.error("Ошибка получения данных о недельном прогрессе", err);
                });
        }
    }, [selectedType]);

    const kssVisible = (data) => {
        var list = []
        data.map(item => {
            if (item){
                return !item?.skip ? list.push(item?.status) : null
            }
        })
        return list
    }
    if (kssData.length === 0) return <></>;

    return (
        <Layout>
            <Header>
                <Avatar src={avatar} alt="avatar" />
                <TypographyHeader>Мой KSS</TypographyHeader>
                {/* <Chip variant="lightBlue" size="small" label={date} sx={{ marginLeft: 'auto' }} /> */}
            </Header>
            <KeepStopStart>
                <Selector
                    ioVars={{ isKeepVisible, isStopVisible, isStartVisible }}
                    sliderRef={sliderRef}
                    kssVisible={kssVisible(kssData)}
                />
            </KeepStopStart>
            <Cards ref={sliderRef}>
                {
                    kssData.map(item=> {
                        if (!item) return
                        const type = item?.status.toLowerCase()
                        let ref;
                        switch (type) {
                            case "keep":
                                ref = keepRef
                                break
                            case "stop":
                                ref = stopRef
                                break
                            case "start":
                                ref = startRef
                        }

                        return(
                            <SliderCard
                                key={`slide-${type}`}
                                reference={ref}
                                setOpen={setOpenModal}
                                data={item}
                                setSelected={setSelectedType}
                                setOpenInfo={setInfoOpen}
                                info={true}
                                canScore={!Boolean(adminData.appAdminPanel) && new Date(item.created_at).toDateString() !== new Date().toDateString()}
                                />
                        )
                    })
                }
            </Cards>
            <RateModal setData={setData}
                       open={openModal}
                       onClose={()=> {setOpenModal(false)}}
                       setSelectedData={setSelectedType}
                       selectedType={selectedType}
            />
            {infoOpen ? <FeedbackModal
                    behaviorData={selectedType}
                    open={infoOpen}
                    setIsOpen={setInfoOpen}
                    background={getColorType(selectedType?.status)}
                />
             : null}
        </Layout>
    );
};

export default MySessionMyKss;
