import React, { useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/system';
import { Button, Chip, Typography, Skeleton } from '@mui/material';
import { colors } from '../../../styles/globalStyles';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { kssTrackerDataAtom, timeLineDataAtom } from '../../../recoil/atoms/trackerPageAtoms';
import { onlyShortMonth, onlyYear } from '../../../functions/nextSessionFormat';
import TimeLineChip from './timeLineChip';
import { isTodayOrYesterdayBefore7am } from '../../../util/date';
import { getBarHeight } from '../../../util/render';

const TimeLineWrap = styled('div')({
    background: 'rgba(255, 255, 255, 0.9)',
    width: '100%',
    maxWidth: 390,
    display: 'flex',
    flexDirection: 'column',
    gap: 17,
    borderRadius: '40px 40px 0px 0px',
    borderWidth: '0.33px 0.33px 0px 0.33px',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.3)',
    backdropFilter: 'blur(10px)',
    padding: 17,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    marginInline: 'auto',
});

const Greeting = styled(Typography)({
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 1,

    '& span': {
        color: colors.main,
    },
});
const Header = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '0px 10px 0px',
    gap: 10,
});

const Dates = styled('div')({
    display: 'flex',
    overflow: 'auto',
    overflowY: 'hidden',
    gap: 2,

    margin: ' 0 -17px',

    scrollSnapType: 'x mandatory',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
});

const SkeletonBlock = styled(Skeleton)({
    width: 69.6,
    height: 69.6,
    borderRadius: 23,
    overflow: 'hidden',
    padding: 0,
    // flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    '&:last-of-type': {
        marginRight: '17px',
    },

    '&:first-of-type': {
        marginLeft: '17px',
    },
});

const DateBlockWrap = styled(Button)({
    width: 69.6,
    height: 69.6,
    borderRadius: 23,
    background: colors.transparentMain,
    color: colors.darkBlue,
    overflow: 'hidden',
    padding: 0,

    scrollSnapAlign: 'center',

    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,

    '&:last-of-type': {
        marginRight: '17px',
    },

    '&:first-of-type': {
        marginLeft: '17px',
    },

    '& .day ': {
        fontWeight: 700,
        fontSize: 22,
    },

    '& .month': {
        fontWeight: 500,
        fontSize: 10,
    },

    '&:focus': {
        color: colors.main,
        border: `2px solid ${colors.main}`,
    },

    '&:hover': {
        background: colors.transparentMain,
        color: colors.main,
        border: `2px solid ${colors.main}`,
    },
});

const Plot = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'end',
    gap: 2,
});

const PlotBar = styled('div')(({ type }) => ({
    background: getGradByType(type),
    content: '""',
    width: '100%',
    borderRadius: '7px 7px 0 0',
    transition: 'height 1s'
}));

const PeriodContainer = styled('div')({
    display: 'flex',

    gap: 2,
});

const DatesWrap = styled('div')({
    position: 'relative',

    // marginRight: "10px",
    // gap: 2,
});

const SessionName = styled(Typography)({
    position: 'sticky',
    left: '27px',
    marginLeft: 27,
    marginRight: 27,
    width: 'max-content',
});

const PlotWrap = styled('div')({
    paddingTop: 10,
    width: 69.6,
    height: 69.6,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
});

const PlotDate = styled('div')({ display: 'flex', gap: 2, fontSize: 10 });

const getGradByType = (type) => {
    switch (type) {
        case 'keep':
            return colors.gradient.bg.keep;
        case 'stop':
            return colors.gradient.bg.stop;
        case 'start':
            return colors.gradient.bg.start;
        default:
            return colors.main;
    }
};

const Timeline = ({ setCanScore }) => {
    const data = useRecoilValue(timeLineDataAtom);
    const timelineRef = useRef();
    const todayRef = useRef(null);
    const today = new Date().toISOString().substring(0, 10);

    const [breakPoinstRef, isVisible] = useOnScreen({
        root: null,
        rootMargin: '0px -43% 0px -43%',
        threshold: 0.5,
    });

    useEffect(() => {
        if (!timelineRef.current) return;
        if (todayRef?.current) {
            let parentElement = todayRef.current.parentElement;
            timelineRef.current.scrollTo({
                left: parentElement.offsetLeft - parentElement.clientWidth,
                behavior: 'smooth',
            });
            let todayEl = document.getElementById(today)
            if (todayEl){
                document.getElementById(today).focus()
                setCanScore(true)
            }
        }
    }, []);

    return (
        <TimeLineWrap>
            <Header>
                <Greeting>
                    Привет, <span>{localStorage.getItem('username')}</span>!
                </Greeting>
                <TimeLineChip isVisible={isVisible} />
            </Header>
            <DatesWrap>
                <Dates ref={timelineRef}>
                    {data.periods.map((period) => {
                        return period.days.map((item, index) => (
                            <DateBlock
                                key={`${period.period}-${index}`}
                                dateObj={item}
                                maxValue={data.max_score}
                                todayRef={todayRef}
                                breakPoinstRef={breakPoinstRef}
                                breakpoints={data.breakpoints}
                                kss={period.kss}
                                setCanScore={setCanScore}
                            />
                        ));
                    })}
                </Dates>
            </DatesWrap>
        </TimeLineWrap>
    );
};

const DateBlock = ({ dateObj, maxValue, breakPoinstRef, todayRef, breakpoints, kss, setCanScore }) => {
    let checkKss = dateObj.scores.keep || dateObj.scores.stop || dateObj.scores.start;
    let dateJs = new Date(dateObj.date);
    let numDay = dateJs.getDate();
    let isoDate = dateJs.toISOString().substring(0, 10);
    let isToday = isTodayOrYesterdayBefore7am(dateJs);
    let idx = breakpoints.indexOf(isoDate);
    const setKssTrackerData = useSetRecoilState(kssTrackerDataAtom);

    const setData = (data) =>{
        if(data.length === 0) return
        let newData = data.slice().map(item => {
            let newObj = {...item}
            newObj.score = dateObj.scores[newObj.status]
            return newObj
        })
        let sortingArr = ["keep", "stop", "start"]
        newData.sort((a, b) => sortingArr.indexOf(a.status) - sortingArr.indexOf(b.status));
        setKssTrackerData(newData)
        setCanScore(isToday)
    }

    return (
        <DateBlockWrap
            onClick={() => setData(kss)}
            id={isoDate}
            ref={(el) => (idx !== -1 ? (breakPoinstRef.current[idx] = el) : null)}
        >
            {checkKss ? (
                <>
                    <PlotWrap ref={isToday ? todayRef : null}>
                        <PlotDate>
                            <div>{numDay}</div>
                            <div>{dateObj.weekday}</div>
                        </PlotDate>
                        <Plot>
                            <PlotBar
                                type={'keep'}
                                sx={{
                                    height: getBarHeight(dateObj.scores.keep?.result, maxValue, 4),
                                }}
                            />
                            <PlotBar
                                type={'stop'}
                                sx={{
                                    height: getBarHeight(dateObj.scores.stop?.result, maxValue, 4),
                                }}
                            />
                            <PlotBar
                                type={'start'}
                                sx={{
                                    height: getBarHeight(dateObj.scores.start?.result, maxValue, 4),
                                }}
                            />
                        </Plot>
                    </PlotWrap>
                </>
            ) : (
                <div ref={isToday ? todayRef : null}>
                    <div className={'day'}>{numDay}</div>
                    <div className={'month'}>{dateObj.weekday}</div>
                </div>
            )}
        </DateBlockWrap>
    );
};

const useOnScreen = (options) => {
    const containerRef = useRef([]);
    const [isVisible, setIsVisible] = useState([]);

    const callbackFunction = (entries) => {
        const [entry] = entries;

        if (entry.isIntersecting && isVisible.target !== entry.target) {
            setIsVisible(entry);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(callbackFunction, options);
        if (containerRef.current)
            containerRef.current.forEach((el) => {
                observer.observe(el);
            });

        return () => {
            if (containerRef.current)
                containerRef.current.forEach((el) => {
                    if (el !== null){
                        observer.unobserve(el);
                    }
                });
        };
    }, [containerRef, options]);

    return [containerRef, isVisible];
};

export default Timeline;
