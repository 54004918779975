import { Button, Card, IconButton, Typography } from "@mui/material";
import { ReactComponent as InfoIcon } from "../../../images/info_icon.svg";
import { ReactComponent as ArrowRight } from "../../../images/arrow_right.svg";
import React from "react";
import { getColorType } from "../../../pages/trackerPage";
import { styled } from "@mui/system";
import BgImage from "../../../images/bg.png";
import { colors } from "../../../styles/globalStyles";
import { ReactComponent as Star0 } from "../../../images/0star.svg";
import { ReactComponent as Star1 } from "../../../images/1star.svg";
import { ReactComponent as Star2 } from "../../../images/2stars.svg";
import { ReactComponent as Star3 } from "../../../images/3stars.svg";
import { ReactComponent as Star4 } from "../../../images/4stars.svg";
import { ReactComponent as Star5 } from "../../../images/5stars.svg";
import { ReactComponent as Star6 } from "../../../images/6stars.svg";
import { ReactComponent as Star7 } from "../../../images/7stars.svg";
import { ReactComponent as Star8 } from "../../../images/8stars.svg";
import { ReactComponent as Star9 } from "../../../images/9stars.svg";
import { ReactComponent as Star10 } from "../../../images/10stars.svg";

const StyledCard = styled(Card)(({ backgroundcolor }) => ({
    background: `${backgroundcolor}, url(${BgImage})`,
    backgroundSize: 'cover',
    backgroundBlendMode: 'multiply',
    color: colors.white,
    width: "95%",
    height: "100%",
    minHeight: 320,
    borderRadius: "40px 40px 0 0 ",
    boxShadow: "unset",
    marginBottom: 0,
    marginTop: "auto",
    flexShrink: 0,
    scrollSnapAlign: "center",
    padding: 17,
    display: "flex",
    flexDirection: "column",
    "@media(min-width:1440px)": {
        maxWidth: "23vw",
    },
    '&:first-of-type': {
        marginLeft: '17px',
    },
    '&:last-of-type': {
        marginRight: '17px',
    },
}))

const Header = styled("div")({
    display: "flex",
    justifyContent: "flex-end"
})

const Case = styled("div")({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
})

const Value = styled("div")({
    display: "flex",
    alignItems: "center",
    gap: 5
})

const ValueText = styled(Typography)({
    fontWeight: 400,
    color: colors.white,
    lineHeight: "18px",
    fontSize: 14,
})

const Behavior = styled(Typography)({
    color: colors.white,
    fontWeight: 800,
    fontSize: 28,
    textAlign: "center",
    marginBottom: 10,
})

const RateButton = styled(Button)({
    background: colors.transparentWhite,
    color: colors.white,
    height: 81,
    borderRadius: 23,
    fontWeight: 400,
    flexShrink: 0,

    marginTop: 'auto',

    '&:hover': {
        background: 'rgba(0, 0, 0, 0.15)'
    }
})

const RateValue = styled("div")({
    display: "flex",
    flexDirection: "column",

    "& .rate-value": {
        fontWeight: 800,
        fontSize: 32,
        color: colors.white
    },

    "& .stars": {
        color: colors.yellow
    }
})

const renderStars = (value) => {

    switch (value) {
        case 0:
            return <Star0 />
        case 1:
            return <Star1 />
        case 2:
            return <Star2 />
        case 3:
            return <Star3 />
        case 4:
            return <Star4 />
        case 5:
            return <Star5 />
        case 6:
            return <Star6 />
        case 7:
            return <Star7 />
        case 8:
            return <Star8 />
        case 9:
            return <Star9 />
        case 10:
            return <Star10 />

    }
}

const SliderCard = ({ style, data, setOpen, reference, setSelected, info = true, setOpenInfo, canScore = true }) => {

    const getFont = (text) => {
        if (!text) return
        if (text.length < 65) return 28
        if (text.length < 80) return 24
        if (text.length < 120) return 20
        if (text.length < 160) return 18
        if (text.length < 200) return 16
        return 14
    }

    if (data.skip === true) return

    return (
        <StyledCard ref={reference} backgroundcolor={getColorType(data.status)} style={style}>
            {
                info ? (
                    <Header>
                        <IconButton sx={{ width: 36, height: 36 }} onClick={() => {
                            setOpenInfo(true)
                            setSelected(data)
                        }}>
                            <InfoIcon />
                        </IconButton>
                    </Header>
                ) : null
            }

            <Case>
                <Value>
                    <svg width={10} height={10} viewBox="0 0 10 10" fill={data.status === "stop" ? colors.red : colors.green}
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5" cy="5" r="5" />
                    </svg>
                    <ValueText>
                        {data["value_name"]}
                    </ValueText>
                </Value>
                <Behavior
                    style={{ fontSize: getFont(data.title) }}
                >
                    {data.title}
                </Behavior>
            </Case>
            {!data.score ? (
                canScore ? (
                    <RateButton endIcon={<ArrowRight />} onClick={() => {
                        setSelected(data)
                        if (setOpen) setOpen(true)
                    }}>
                        Оценить
                    </RateButton>
                ) : (null)
            ) : (
                <RateButton
                    disabled={!canScore}
                    style={!canScore ? { background: 'none' } : null}
                    onClick={() => {
                        setSelected(data)
                        if (setOpen) setOpen(true)
                    }}
                >
                    <RateValue>
                        <Typography className={"rate-value"} style={{ marginBottom: "-10px" }}>
                            {data.score.result}
                        </Typography>
                        <div className={"stars"}>
                            {renderStars(data.score.result)}
                        </div>
                    </RateValue>
                </RateButton>
            )}
        </StyledCard>
    )
}

export default SliderCard